import { Alert, Button, Card, Col, Divider, List, Row, Tag, Typography } from "antd";
import { FileUpload } from "components/common/fileUpload/fileUpload";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import { FileTypes } from "constants/files";
import { PermissionList } from "constants/permissions";
import Restricted from "features/auth/restricted";
import { useAppSelector } from "hooks/useAppSelector";
import { useState } from "react";
import { UploadFileResultItemModel } from "shared/models/uploadFileResultItemModel";
import { UploadFileResultModel } from "shared/models/uploadFileResultModel";
const { Paragraph, Text } = Typography;

function BulkCreateEmployeePage() {

    const [uploadResult, setUploadResult] = useState<UploadFileResultModel>();
    const [errorItems, setErrorItems] = useState<UploadFileResultItemModel[]>();
    const [successItems, setSuccessItems] = useState<UploadFileResultItemModel[]>();
    const supplier = useAppSelector(store => store.currentUser.supplier);

    const onFileUpload = (uploadResult: UploadFileResultModel) => {
        setUploadResult(uploadResult);
        setSuccessItems(uploadResult.details.filter(x => x.isSuccess));
        setErrorItems(uploadResult.details.filter(x => !x.isSuccess));
    }

    if (!supplier)
        return <></>;

    return (
        <Card>
            <PageTitle>Carga masiva de colaboradores</PageTitle>
            <Row justify={"center"}>
                <Restricted to={PermissionList.EmployeeCreate}>
                    <Col>

                        <Alert
                            message="Atención"
                            description={
                                <>
                                    <Paragraph>
                                        Para importar colaboradores, debes utilizar nuestra plantilla predeterminada.
                                        Asegúrate de que el archivo cargado respete el orden y los nombres de las columnas de la plantilla.
                                    </Paragraph>
                                    <Paragraph>
                                        Puedes descargar la plantilla haciendo clic en el siguiente enlace:
                                        <br />
                                        <Button type="link" href={'/docs/BulkEmployeeImportTemplate.csv'} download="EslavonPlantillaImportarColaboradores.csv">
                                            Descargar plantilla
                                        </Button>
                                    </Paragraph>
                                    <Paragraph>
                                        <Text strong>
                                            Nota:
                                        </Text>{" "}
                                        Revisar que el archivo cargado esté en formato CSV y contenga la información de manera precisa evitará errores durante la importación.
                                    </Paragraph>
                                    <Row>
                                        <FileUpload supplierId={supplier.id}
                                            onUploadComplete={onFileUpload}
                                            fileType={FileTypes.EmployeeBulkImport}></FileUpload>
                                    </Row>
                                </>
                            }
                            type="info"
                            showIcon
                        />

                        {/* <Button type="link">
                            <a href={'/docs/BulkEmployeeImportTemplate.csv'} download="EslavonPlantillaImportarColaboradores.csv">Descargar plantilla</a>
                        </Button> */}
                    </Col>
                </Restricted>
            </Row>

            <Row justify={"center"}>
                <Col span={24}>
                    {
                        uploadResult &&
                        <>
                            <div>
                                <Alert
                                    message={
                                        <>
                                            <h3>Resultados de la carga masiva de colaboradores</h3>
                                            <ul>
                                                <li>Registros encontrados en el archivo: {(successItems?.length ?? 0) + (errorItems?.length ?? 0)}</li>
                                                <li>Colaboradores importados: {successItems?.length}</li>
                                                <li>Registros con error: {errorItems?.length}</li>
                                            </ul>
                                        </>
                                    }
                                    type={uploadResult.isSuccess ? "success" : "error"}
                                    showIcon
                                />
                                <Divider />
                                <Divider>Registros con error ({errorItems?.length})</Divider>
                                <List
                                    dataSource={errorItems}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.identifier}
                                                description={<><Tag color="red">Error</Tag>{item.resultMessage}</>} />
                                        </List.Item>
                                    )}
                                />

                                <Divider>Registros importados exitosamente ({successItems?.length})</Divider>
                                <List
                                    dataSource={successItems}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.identifier}
                                                description={<Tag color="green">Correcto</Tag>} />
                                        </List.Item>
                                    )}
                                />

                            </div>
                        </>
                    }
                </Col>

            </Row>
        </Card>
    );
}

export default BulkCreateEmployeePage;