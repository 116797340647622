import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { uploadDocument } from "api/coreapi/files";
import { FileTypes } from "constants/files";
import { UploadFilePayload } from "shared/models/payloads/uploadFilePayload";
import { UploadFileResultModel } from "shared/models/uploadFileResultModel";

interface FileUploadProps {
    supplierId?: string,
    customerId?: string,
    employeeId?: string,
    fileType: FileTypes,
    onUploadComplete?: (uploadResult: UploadFileResultModel) => void;
}

const validDocTypes = {
    [FileTypes.EmployeeBulkImport]: ['text/csv'],
};
export function FileUpload(props: FileUploadProps) {
    const beforeUpload = (file: File) => {
        const validTypes = validDocTypes[props.fileType] || [];
        if (!validTypes.includes(file.type)) {
            message.error(`Tipo de archivo inválido. Los tipos permitidos son: ${validTypes.join(', ')}`);
            return Upload.LIST_IGNORE; // Prevent upload
        }
        return true;
    };

    const customUpload = async (options: any) => {
        const { file, onSuccess, onError } = options;
        const payload: UploadFilePayload = {
            file: file,
            name: file.name,
            fileTypeId: props.fileType,
            supplierId: props.supplierId,
            employeeId: props.employeeId,
            customerId: props.customerId
        };

        try {
            var response = await uploadDocument(payload);

            if (!response.result) {
                throw new Error('Error cargando el archivo.');
            }

            if (props.onUploadComplete) {
                props.onUploadComplete(response.result);
            }

            onSuccess(response, file); // Notify Ant Design of success

        } catch (error: any) {
            console.error(error);
            message.error("Error cargando el archivo.");
            if (error.errors) {
                message.error(error.errors);
            }
            onError(error); // Notify Ant Design of error
        }
    };


    if (!props.fileType) {
        return <></>
    }

    return (

        <Upload
            accept={validDocTypes[props.fileType].join(',')}
            beforeUpload={beforeUpload}
            customRequest={customUpload}>
            <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
        </Upload>

    )
}