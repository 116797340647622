import { coreApiInstance } from "api/apiInstance";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { UploadFilePayload } from "shared/models/payloads/uploadFilePayload";
import { UploadFileResultModel } from "shared/models/uploadFileResultModel";

const filesResourceUri = "files";

export const uploadDocument = async (payload: UploadFilePayload): Promise<EslavonApiResponse<UploadFileResultModel>> => {
    try {
        const actionUri = `${filesResourceUri}`;
        let formData = new FormData();
        formData.append('formFile', payload.file);
        formData.append('fileName', payload.name);
        formData.append('fileTypeId', payload.fileTypeId.toString());

        if (payload.supplierId) {
            formData.append('supplierId', payload.supplierId);
        }

        if (payload.customerId) {
            formData.append('customerId', payload.customerId);
        }

        if (payload.employeeId) {
            formData.append('employeeId', payload.employeeId);
        }

        const { data: result } = await coreApiInstance.post<EslavonApiResponse<UploadFileResultModel>>(actionUri, formData);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}