import { coreApiInstance } from "api/apiInstance";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { EmployeeLockReason } from "shared/models/employeeLockReasonModel";
import { Employee } from "shared/models/employeeModel";
import { EnumTypeModel } from "shared/models/enumTypeModel";
import { CreateEmployeePayload } from "shared/models/payloads/createEmployeePayload";
import { EditEmployeePayload } from "shared/models/payloads/editEmployeePayload";
import { UpdateEmployeeLockPayload } from "shared/models/payloads/updateEmployeeLockPayload";

const employeesResourceUri = 'employees';

export const createEmployee = async (payload: CreateEmployeePayload): Promise<EslavonApiResponse<Employee>> => {
    try {
        const { data: result } = await coreApiInstance.post<EslavonApiResponse<Employee>>(employeesResourceUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const editEmployee = async (payload: EditEmployeePayload): Promise<EslavonApiResponse<Employee>> => {
    try {
        const { data: result } = await coreApiInstance.put<EslavonApiResponse<Employee>>(employeesResourceUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getEmployeeById = async (employeeId: string): Promise<EslavonApiResponse<Employee>> => {
    try {
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<Employee>>(`${employeesResourceUri}/${employeeId}`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getEmployeeStatuses = async (): Promise<EslavonApiResponse<EnumTypeModel[]>> => {
    try {
        const { data: suppliers } = await coreApiInstance.get<EslavonApiResponse<EnumTypeModel[]>>(`${employeesResourceUri}/statuses`);
        return suppliers;
    }
    catch (error: any) {
        if (error.response.data) {
            return error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getEmployeeLockReasons = async (): Promise<EslavonApiResponse<EmployeeLockReason[]>> => {
    try {
        const { data: suppliers } = await coreApiInstance.get<EslavonApiResponse<EmployeeLockReason[]>>(`${employeesResourceUri}/lockreasons`);
        return suppliers;
    }
    catch (error: any) {
        if (error.response.data) {
            return error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const updateEmployeeLock = async (payload: UpdateEmployeeLockPayload): Promise<EslavonApiResponse<boolean>> => {
    try {
        const { data: result } = await coreApiInstance.put<EslavonApiResponse<boolean>>(`${employeesResourceUri}/updatelock`, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const deleteEmployee = async (id: string): Promise<EslavonApiResponse<boolean>> => {
    try {
        const { data: result } = await coreApiInstance.delete<EslavonApiResponse<boolean>>(`${employeesResourceUri}/${id}`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}